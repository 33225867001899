import React, { useEffect } from 'react';
import { API_URL } from '../../config';
import axiosInstance from '../../api/axiosConfig';

export const UpdateToken: React.FC = () => {
    const UPDATE_INTERVAL: number = 60000 * 15;

    useEffect(() => {
        const checkStatus = async (): Promise<void> => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                try {
                    const res = await axiosInstance.post(API_URL + 'auth/refresh', {}, {
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    });
                    if (res?.data?.access_token) {
                        localStorage.setItem('accessToken', res.data.access_token);
                    }
                } catch (error) {
                    console.log('Refreshing error', error);
                }
            }
        };

        checkStatus();

        const checkStatusInterval = setInterval(() => {
            checkStatus();
        }, UPDATE_INTERVAL);

        return () => clearInterval(checkStatusInterval);
    }, [UPDATE_INTERVAL]);

    return null;
};