import { Lucid, Blockfrost } from "lucid-cardano";
//import { BrowserWallet } from '@meshsdk/core';
let Buffer = require('buffer/').Buffer
//let Lucid = require('lucid-cardano');

export class CardanoWallet {

    constructor(wallet) {  // Constructor

        this.wallet = wallet;

    }

    checkForWallet = () => {
        return !!window?.cardano?.[this.wallet];
    }

    checkWalletEnabled = async () => {

        let walletIsEnabled = false;

        try {
            walletIsEnabled = await window.cardano[this.wallet].isEnabled();
        } catch (err) {
            return err;
        }

        return walletIsEnabled;

    }

    enableWallet = async () => {
        try {
            this.API = await window.cardano[this.wallet].enable();
        } catch (err) {
            return err;
        }
        return this.checkWalletEnabled();
    }

    getAPIVersion = () => {
        const walletAPIVersion = window?.cardano?.[this.wallet].apiVersion;
        return walletAPIVersion;
    }

    getWalletName = () => {
        const walletName = window?.cardano?.[this.wallet].name;
        return walletName;
    }

    getNetworkId = async () => {

        try {
            const networkId = await this.API.getNetworkId();
            return networkId;
        } catch (err) {
            return err;
        }

    }

    getChangeAddress = async (Address) => {
        try {
            const raw = await this.API.getChangeAddress();
            const changeAddress = Address.from_bytes(Buffer.from(raw, "hex")).to_bech32()
            return changeAddress;
        } catch (err) {
            console.log(err)
        }
    }

    getUtxos = async (Utxos) => {

        return [];

        // return Utxos;

    }

    getBalance = async () => {

    }

    getTxUnspentOutputs = async () => {

    }

    buildSendADATransaction = async (address, sum) => {

        const lucid = await Lucid.new(
            new Blockfrost(
                "https://cardano-mainnet.blockfrost.io/api/v0/",
                "mainnet74Nkrclveed1OtMddmpZg5hFUqXf74X6",
            ), "Mainnet"
        );

        lucid.selectWallet(this.API);

        const tx = await lucid.newTx()
            .payToAddress(address, { lovelace: this.toLovelace(sum) })
            .complete();

        const signedTx = await tx.sign().complete();

        const txHash = await signedTx.submit();

        return txHash;

    }

    buildSendTokenTransaction = async (address, policy, asset, amount = 1) => {

        const lucid = await Lucid.new(
            new Blockfrost(
                "https://cardano-mainnet.blockfrost.io/api/v0/",
                "mainnet74Nkrclveed1OtMddmpZg5hFUqXf74X6",
            ), "Mainnet"
        );

        lucid.selectWallet(this.API);

        const tx = await lucid.newTx()
            .payToAddress(address, { [policy + asset]: amount })
            .complete();

        const signedTx = await tx.sign().complete();

        const txHash = await signedTx.submit();

        return txHash;

    }

    getRewardAddresses = async () => {

        const lucid = await Lucid.new(
            new Blockfrost(
                "https://cardano-mainnet.blockfrost.io/api/v0/",
                "mainnet74Nkrclveed1OtMddmpZg5hFUqXf74X6",
            ), "Mainnet"
        );

        lucid.selectWallet(this.API);

        return await lucid.wallet.address();

    }

    toLovelace = (ada) => {
        const ADA_PER_LOVELACE = 1000000;
        return parseFloat(ada) * ADA_PER_LOVELACE;
    }

}