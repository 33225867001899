export const EURformatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
});

export const ADAformatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ADA',
});

export const formatCurrency = (value: number, currency: { name: string, symbol: string } | null) => {
    if (!currency) {
        return value.toFixed(2);
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency.name,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

export const formatCurrencyWithoutSymbol = (value: number, currency: { name: string, symbol: string }) => {
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};