import React from "react";

export const ProcessingAnimation: React.FC = () => {
    return (
        <div className="processing">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
            <div className="shape shape-4"></div>
        </div>
    );
};