export const tabs = [
    {
        name: 'Buy',
        link: '/'
    },
    {
        name: 'Sell',
        link: '/sell'
    },
    {
        name: 'Rent',
        link: '/rent'
    }
]